<script>
import Localized from '@/views/layouts/Localized'

export default {
  /**
   * Extend the Localized layout.
   */
  extends: Localized,

  /**
   * Provide the component name to localize.
   */
  name: 'Imprint'
}
</script>
